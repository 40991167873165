export function useGenerateSearchUrl(title = '', location = '', jobType = '', workType = '') {
  const jobTypes = ['full-time', 'part-time', 'freelance', 'internship']
  const workTypes = ['remote', 'onsite']

  let url = '/search'

  const formattedTitle = encodeURIComponent(title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, ''))
  const formattedLocation = encodeURIComponent(location.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, ''))
  jobType = jobType.replace('_', '-').toLowerCase()
  const isValidJobType = jobTypes.includes(jobType)
  const isValidWorkType = workTypes.includes(workType.toLowerCase())

  if (isValidJobType && formattedLocation)
    url += `/${jobType.toLowerCase()}-jobs-in-${formattedLocation}`
  else if (isValidWorkType && formattedLocation)
    url += `/${workType.toLowerCase()}-jobs-in-${formattedLocation}`
  else if (isValidJobType && formattedTitle)
    url += `/${jobType.toLowerCase()}-${formattedTitle}-jobs`
  else if (isValidWorkType && formattedTitle)
    url += `/${workType.toLowerCase()}-${formattedTitle}-jobs`
  else if (isValidJobType)
    url += `/${jobType.toLowerCase()}-jobs`
  else if (isValidWorkType)
    url += `/${workType.toLowerCase()}-jobs`
  else if (formattedTitle && formattedLocation)
    url += `/${formattedTitle}-jobs-in-${formattedLocation}`
  else if (formattedTitle)
    url += `/${formattedTitle}-jobs`
  else if (formattedLocation)
    url += `/jobs-in-${formattedLocation}`

  return url
}
